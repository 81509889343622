import { TextField } from "@mui/material"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import GetContacts from "../../../queries/GetContacts"
import EditContacts from "../../../queries/EditContacts"
import Loading from "../../fixed-elements/Loading"
import CheckAdmin from "../../../queries/CheckAdmin"

const ContactsPage = () => {
    const navigate = useNavigate()
    const [address, setAddress] = useState("")
    const [phone_number, setPhoneNumber] = useState("")
    const [tg_link, setTgLink] = useState("")
    const [onSave, setOnSave] = useState(false)
    const [saveLoading, setSaveLoading] = useState(false)
    const [isUserAdmin, setIsUserAdmin] = useState(false)

    const editContactsHandler = () => {
        setSaveLoading(true)
        EditContacts(address, phone_number, tg_link).then(response => {
            setSaveLoading(false)
            if(response.result){
                setOnSave(true)
                let timer = setTimeout(() => {
                    setOnSave(false)
                    clearTimeout(timer)
                }, 1000);
            }
        })
    }

    useEffect(() => {

        let tg = window.Telegram.WebApp;
        if (tg.initDataUnsafe.user) // ВНИМАНИЕ НЕ!
        CheckAdmin(tg.initDataUnsafe.user.id).then(response => { // 918184279
            setIsUserAdmin(response.result)
            !response.result && navigate("/")
        })


        GetContacts(0).then(response => {
            if(response.result && response.result.data.length === undefined){
                setAddress(response.result.data.adress)
                setPhoneNumber(response.result.data.contact_phone)
                setTgLink(response.result.data.telegram)
            }
        })
    }, [navigate])

    return isUserAdmin ? <div className="contacts-page-container admin-block-container">
        <div onClick={() => navigate(-1)} className="back-block">
            <svg xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 -960 960 960" width="30"><path d="M560-240 320-480l240-240 56 56-184 184 184 184-56 56Z" /></svg>
            <p>Назад</p>
        </div>
        <div className="contacts-content">
            <h2>Контакты</h2>
            <TextField value={phone_number} onChange={e => {console.log(e.target.value);setPhoneNumber(e.target.value)}} label="Номер телефона" />
            <TextField value={address} onChange={e => setAddress(e.target.value)} label="Адрес" />
            <TextField value={tg_link} onChange={e => setTgLink(e.target.value)} label="Telegram" />
            {onSave
                ? <div className="save_message">Изменения сохранены!</div>
                : <div onClick={editContactsHandler} className="but-send">
                    {saveLoading ? <Loading fill={"#fff"} /> : "Сохранить"}
                </div>}

        </div>
    </div>
    : <Loading />
}

export default ContactsPage