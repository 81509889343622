import { TextField } from "@mui/material"
import { DatePicker } from "@mui/x-date-pickers"
import dayjs from "dayjs"
import { useEffect, useMemo, useState } from "react"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"
import Checkboxs from "./Checkboxs"
import SelectTime from "./SelectTime"
import { useDispatch, useSelector } from "react-redux"
import AddOrder from "../../queries/AddOrder"
import GetProducts from "../../queries/GetProducts"
import { useNavigate } from "react-router-dom"
import GetPriceFromAddress from "../../queries/GetPriceFromAddress"
import { _global_domain } from "../../domain-address"
import PointsSelectBlock from "./PointsSelectBlock"
import { TARGET_WS_INFO_POINTS_TARGET } from "../../reducers/types"
import Loading from "../fixed-elements/Loading"
import ShopInfo from "./ShopInfo"

const BuildOrder = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const user_info = useSelector((s) => s.app.user_info)
  const basket_storage = useSelector((s) => s.app.basket)
  const shops = useSelector((s) => s.app.shops)

  const [priceDelivery, setPriceDelivery] = useState(null)
  const [priceOrder, setPriceOrder] = useState(0)
  const [activeButtonPoints, setActiveButtonPoints] = useState(0)

  // информация о заявке
  const [id_select_deliver, setIdSelectDeliver] = useState(1)

  const [date_of_deliver, setDateOfDeliver] = useState(dayjs())
  const [order_time, setOrderTime] = useState(1)

  const [errors, setErrors] = useState({
    name: true,
    number: true,
    address: true,
    number_another: true,
    name_another: true,
    postcard_text: true,
    limitError: true,
    paymentError: true,
  })

  const [addressValid, setAddressValid] = useState(true)
  const [client_name, setClientName] = useState("")
  const [client_number, setClientNumber] = useState()
  const [client_address, setClientAddress] = useState("")

  const [client_other, setClientOther] = useState(false)
  const [client_number_another, setClientNumberAnother] = useState()
  const [client_name_another, setClientNameAnother] = useState("")
  const [client_postcard, setClientPostcard] = useState(false)
  const [client_postcard_text, setClientPostcardText] = useState("")
  const [client_anonim, setClientAnonim] = useState(false)
  const [loaderButtonOrderSend, setLoaderButtonOrderSend] = useState(false)
  // конец информации о заявке

  const paymentHandler = async () => {
    if(!loaderButtonOrderSend){
      const new_errors = {
        name: client_name ? true : false,
        number: client_number ? true : false,
        address: (client_address && id_select_deliver === 2) || id_select_deliver !== 2 ? true : false,
        number_another: (client_number_another && client_other) || !client_other ? true : false,
        name_another: (client_name_another && client_other) || !client_other ? true : false,
        postcard_text: (client_postcard_text && client_postcard) || !client_postcard ? true : false,
        priceDelivery: (id_select_deliver === 2 && !priceDelivery) ? false : true,
        limitError: true,
        paymentError: true,
      }
      setErrors(new_errors)
  
      if ((!Object.keys(new_errors).some((er) => new_errors[er] === false))) {
        if((id_select_deliver === 2 && addressValid) || id_select_deliver === 1) sendInfo()
      }else{
        document.querySelector(".global-container").scrollTo({
          top: 300,
          behavior: "smooth",
        });
      }
    }
  }

  const sendInfo = async () => {
      let local_data_basket = []
      let local_amount = 0
      setLoaderButtonOrderSend(true)

      basket_storage.length &&
        (await GetProducts(
          null,
          10000,
          null,
          basket_storage.map((el) => el.id),
          shops.find(s => s.selected).id
        ).then((response) => {
          local_amount = response.result.data.reduce((acc, p) => (acc += p.price * basket_storage.filter((b) => b.id === p.id)[0].amount), 0)
          response.result.data.forEach((p) => {
            local_data_basket.push({
              id: p.id,
              name: p.name,
              price: p.price,
              img: p.imgs[0],
              amount: basket_storage.filter((b) => b.id === p.id)[0].amount,
            })
          })
        }))

      let products_order = []

      local_data_basket.forEach((el) => {
        products_order.push({
          id: el.id,
          name: el.name,
          price: el.price,
          amount: +basket_storage.filter((b) => b.id === el.id)[0].amount,
        })
      })
      
      if (id_select_deliver === 1 && local_amount > 40000) setErrors({ ...errors, limitError: false })
      else{
        let tg = window.Telegram.WebApp;
        AddOrder(tg.initDataUnsafe.user.id, products_order, date_of_deliver.format("DD/MM/YYYY"), order_time, client_name, client_number, client_address, client_anonim, client_postcard, client_postcard_text, client_other, client_name_another, client_number_another, id_select_deliver, 0, activeButtonPoints, getWritingPoints)
        //AddOrder(918184279, products_order, date_of_deliver.format("DD/MM/YYYY"), order_time, client_name, client_number, client_address, client_anonim, client_postcard, client_postcard_text, client_other, client_name_another, client_number_another, id_select_deliver, 0, activeButtonPoints, getWritingPoints)
        .then((response) => {
          console.log(response)
          if(response.result){
            if (response.result.id) {
              payOrderHandler(response.result.id)
            }
          }
        })
      }
  }

  async function payOrderHandler (id_order) {
    let tg = window.Telegram.WebApp;
    if(basket_storage.length) {
        await GetProducts(
          null,
          10000,
          null,
          basket_storage.map((el) => el.id),
          shops.find(s => s.selected).id
        ).then(async (response) => {
          const amount = +response.result.data.reduce((acc, p) => (acc += p.price * basket_storage.filter((b) => b.id === p.id)[0].amount), 0) + (+priceDelivery)
          
          await fetch(_global_domain + "/pay_order", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                amount,
                id_user: tg.initDataUnsafe.user.id, // ВНИМАНИЕ  918184279 - id для теста
                id_order
              }),
            }).then((response) => response.json())
            .then((response) => {
              if(response.result) { // здесь хуйня
                localStorage.setItem("payment-id-dff-store", response.result.payment_id)
                localStorage.setItem("payment-url-dff-store", response.result.payment_url)
                localStorage.setItem("payment-first-dff-store", true)
                navigate("/thank")
              }
              else setErrors({ ...errors, paymentError: false })
            })
      })
    }
  }

  const getWritingPoints = useMemo(() => {
    const price30percent = priceOrder * 0.3
    console.log(user_info.points)
    if(user_info.points <= price30percent) {
      return user_info.points
    }else{
      return price30percent
    }
  }, [priceOrder, user_info.points])
  
  const getFinishPrice = useMemo(() => {
    return activeButtonPoints === 0
    ? priceOrder + priceDelivery
    : (priceOrder - getWritingPoints) + priceDelivery    
  }, [priceOrder, getWritingPoints, activeButtonPoints, priceDelivery])


  useEffect(() => {

    setClientAddress(user_info.address || "")
    setClientName(user_info.name || "")
    setClientNumber(user_info.phone_number || "")

  }, [user_info.address, user_info.name, user_info.phone_number])


  useEffect(() => {
    let timer = setTimeout(async() => {

      if(basket_storage.length) {
        await GetProducts(
          null,
          10000,
          null,
          basket_storage.map((el) => el.id),
          shops.find(s => s.selected).id
        ).then(async (response) => {
          if(response.result.data.length){
            const amount = +response.result.data.reduce((acc, p) => (acc += p.price * basket_storage.filter((b) => b.id === p.id)[0].amount), 0)
            setPriceOrder(amount)
          }
        })
      }
    

    if(id_select_deliver === 2 && client_address){
      GetPriceFromAddress(client_address).then(function(response) {
        setPriceDelivery(null)
        if(response.result && response.result.price) {
          setPriceDelivery(response.result.price)
          setAddressValid(true)
        }
        else setAddressValid(false)
      })
    }
  }, 1000)

  return () => clearTimeout(timer)
  }, [client_address, id_select_deliver, basket_storage, shops])


  return (
    <div className="build-order-container">
      <div className="deliver-block">
        <div className={"select-item " + (id_select_deliver === 1 ? "active" : "")} onClick={() => setIdSelectDeliver(1)}>
          <p>Самовывоз</p>
        </div>
        <div className={"select-item " + (id_select_deliver === 2 ? "active" : "")} onClick={() => setIdSelectDeliver(2)}>
          <p>Доставка</p>
        </div>
      </div>
      <div className="order-info-block">

        <ShopInfo/>

        <p className="heading long">Дата и время {id_select_deliver === 1 ? "самовывоза" : "доставки и адрес"}</p>
        <DatePicker className="date-order" onChange={(e) => setDateOfDeliver(e)} value={date_of_deliver} />
        <SelectTime order_time={order_time} setOrderTime={setOrderTime} />

        {id_select_deliver === 2 && <TextField className="address-order input-type" label="Адрес доставки" onChange={(e) => setClientAddress(e.target.value)} value={client_address} fullWidth />}
        {(id_select_deliver === 2 && !errors.address) ? <p className="error">Заполните адрес</p> : ""}
        {(id_select_deliver === 2 && !addressValid) && <p style={{color: "#cccc00", textAlign: "left"}}>Ошибка: адрес доставки не найден, либо вы живете не в Санкт-Петербурге.</p>}

        <p className="heading long">Контактные данные</p>
        <TextField className="name-order input-type" label="Имя покупателя" onChange={(e) => setClientName(e.target.value)} value={client_name} fullWidth />
        {!errors.name ? <p className="error">Заполните имя</p> : ""}
        <PhoneInput value={client_number} onChange={(e) => setClientNumber(e)} containerClass="number-order input-type" country="ru" />
        {!errors.number ? <p className="error">Заполните номер</p> : ""}
        <Checkboxs client_other={client_other} setClientOther={setClientOther} client_postcard={client_postcard} setClientPostcard={setClientPostcard} client_anonim={client_anonim} setClientAnonim={setClientAnonim} client_number_another={client_number_another} setClientNumberAnother={setClientNumberAnother} client_name_another={client_name_another} setClientNameAnother={setClientNameAnother} client_postcard_text={client_postcard_text} setClientPostcardText={setClientPostcardText} errors={errors} id_select_deliver={id_select_deliver} />
      </div>
      {!errors.paymentError && <p style={{color: "red", textAlign: "center"}}>Ошибка: вы находитесь вне бота.</p>}
      {!errors.limitError && <p style={{color: "red", textAlign: "center"}}>Ошибка: при заказе с оплатой при получении, заказ должен быть на сумму не более 40'000 рублей</p>}
      
      {id_select_deliver === 2 && 
        (client_address 
        ? <p style={{color: "#cccc00", textAlign: "center"}}>{priceDelivery ? `Стоимость доствки будет составлять: ${priceDelivery} рублей` : "Расчитываем цену за доставку..."}</p>
        :<p style={{color: "#cccc00", textAlign: "center"}}>Введите адрес, чтобы узнать стоимость доставки</p>)
        }
      <div className="points-info-block">
        <div className="points-block-header">
          <h3>У вас {user_info.points} баллов </h3>
          <div className="points-button" onClick={() => {dispatch({type: TARGET_WS_INFO_POINTS_TARGET})}}>
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#444444">
              <path d="M478-240q21 0 35.5-14.5T528-290q0-21-14.5-35.5T478-340q-21 0-35.5 14.5T428-290q0 21 14.5 35.5T478-240Zm-36-154h74q0-33 7.5-52t42.5-52q26-26 41-49.5t15-56.5q0-56-41-86t-97-30q-57 0-92.5 30T342-618l66 26q5-18 22.5-39t53.5-21q32 0 48 17.5t16 38.5q0 20-12 37.5T506-526q-44 39-54 59t-10 73Zm38 314q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/>
            </svg>
          </div>
        </div>
        <PointsSelectBlock activeButtonPoints={activeButtonPoints} setActiveButtonPoints={setActiveButtonPoints} getWritingPoints={getWritingPoints}/>
      </div>
      <div className="finish-info-block">
        {activeButtonPoints === 0 
        ? <div className="line points_green"><p>Накопление баллов:</p><p>+{(priceOrder*10)/100} баллов</p></div>
        : <div className="line points_red"><p>Списание баллов: </p><p>-{getWritingPoints} баллов</p></div>
      }
      {priceDelivery > 0 && <div className="line"><p>Стоимость доставки: </p><p>{priceDelivery} рублей</p></div>}
        <div className="line"><p>Итоговая сумма:</p><p>{getFinishPrice} рублей</p></div>
      </div>
      <div className="build-order-but" onClick={paymentHandler}>
        {!loaderButtonOrderSend
        ? "Оформить заказ"
        : <div className="loader">
            <Loading fill={"#ffffff"}/>
          </div>}
      </div>
    </div>
  )
}

export default BuildOrder
