import './style.scss';
import { HashRouter, Route, Routes } from 'react-router-dom'
import Home from './components/home/Home.js';
import ActiveMenu from './components/fixed-elements/ActiveMenu.js';
import Header from './components/fixed-elements/Header.js';
import { useEffect, useState } from 'react';
import ProductPage from './components/product-page/ProductPage.js';
import BasketPage from './components/basket-page/BasketPage.js';
import { useDispatch, useSelector } from 'react-redux';
import { CHANGE_CLIENT_ADDRESS, CHANGE_CLIENT_ID, CHANGE_CLIENT_NAME, CHANGE_CLIENT_NUMBER, CHANGE_CLIENT_POINTS, LOAD_BASKET, SET_CATEGORIES, SET_SHOPS } from './reducers/types.js';
import BuildOrder from './components/build-order/BuildOrder.js';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Contacts from './components/contacts/Contacts.js';
import CategoryPage from './components/category-page/CategoryPage.js';
import AdminPanel from './components/admin-panel/AdminPanel.js';
import UserInfo from './components/user-info/UserInfo.js';
import CategoriesPage from './components/admin-panel/categories-page/CategoriesPage.js';
import ContactsPage from './components/admin-panel/contacts-page/ContactsPage.js';
import ProductsPage from './components/admin-panel/products-page/ProductsPage.js';
import OrdersPage from './components/admin-panel/orders-page/OrdersPage.js';
import AddProductPage from './components/admin-panel/add-product-page/AddProductPage.js';
import GetCategories from './queries/GetCategories.js';
import ThankPage from './components/thank-page/ThankPage.js';
import BlockPCPage from './components/block-pc/BlockPCPage.js';
import GetProfile from './queries/GetProfile.js';
import WindowScreenInfoPoints from './components/window-screens/WindowScreenInfoPoints.js';
import DialogSelectShop from './components/dialog-select-shop/DialogSelectShop.js';
import GetShops from './queries/GetShops.js';
import DialogStartSelectShop from './components/dialog-select-shop/DialogStartSelectShop.js';
import Shops from './components/admin-panel/shops-page/Shops.js';
import WindowScreenAddNewShop from './components/window-screens/WindowScreenAddNewShop.js';
import WindowScreenEditNewShop from './components/window-screens/WindowScreenEditNewShop.js';



function App() {

  
  const query = new URLSearchParams(window.location.hash.split('?')[1]);
  const shopId = query.get('shop_id');

  const dispatch = useDispatch();
  const ws_info_points_target = useSelector(s => s.app.ws_info_points_target)
  const [openChangeShop, setOpenChangeShop] = useState(false)
  const [openAddNewShop, setOpenAddNewShop] = useState(false)
  const [openEditNewShop, setOpenEditNewShop] = useState(false)
  const [isShopSelected, setIsShopSelected] = useState(true)
  const [shopIdInWindow, setShopIdInWindow] = useState(-1)
  const [isShopsLoad, setIsShopsLoad] = useState(false)
  const [adminShops, setAdminShops] = useState([])

  

  const Redirect = () => {
    return window.location.assign('http://localhost:3000')
  }


  useEffect(() => {
    let tg = window.Telegram.WebApp;
    const storage_basket = localStorage.getItem("basket-dff-store")
    const storage_shop_id = localStorage.getItem("shop-dff-store")
    storage_basket && dispatch({type: LOAD_BASKET, payload: JSON.parse(storage_basket)})

      
      async function getShopsHandler(show) {
        let localShops = []
        await GetShops(show).then((data) => {
          let newData = Array.isArray(data.result.data) ? data.result.data : [data.result.data]

          console.log(newData)
          localShops = newData.map((s, i) => {
            if(storage_shop_id){
              if(+s.id === +storage_shop_id) return { id: s.id, name: s.name, selected: true, show: s.show }
              else return { id: s.id, name: s.name, selected: false, show: s.show }
            }else{
              if(shopId){
                if (+s.id === +shopId) return { id: s.id, name: s.name, selected: true, show: s.show }
                return { id: s.id, name: s.name, selected: false, show: s.show }
              }else{
                if (i === 0) return { id: s.id, name: s.name, selected: true, show: s.show }
                return { id: s.id, name: s.name, selected: false, show: s.show }
              }
            }
          })


          dispatch({ type: SET_SHOPS, payload: localShops })
        })
        return localShops
      }

      getShopsHandler(1).then((data) => {
        
        if(storage_shop_id){
          
          if(data.some((d) => d.id === +storage_shop_id)){
            setIsShopSelected(true)
            GetCategories(
              storage_shop_id ? storage_shop_id : data.find(s => s.selected).id
            ).then(data_ctg => {
              let newData = Array.isArray(data_ctg.result.data) ? data_ctg.result.data : [data_ctg.result.data]
              dispatch({type: SET_CATEGORIES, payload: newData})
            })
            
          } else{
            setIsShopSelected(false)
          }

        }else{
          setIsShopSelected(false)
        }
    
      })

    if (tg.initDataUnsafe.user) { // ВНИМАНИЕ
      GetProfile(tg.initDataUnsafe.user.id).then((response) => { // ВНИМАНИЕ tg.initDataUnsafe.user.id
        dispatch({ type: CHANGE_CLIENT_ID, payload: response.result.id })
        response.result.data.name && dispatch({ type: CHANGE_CLIENT_NAME, payload: response.result.data.name })
        response.result.data.phone_number && dispatch({ type: CHANGE_CLIENT_NUMBER, payload: response.result.data.phone_number })
        response.result.data.address && dispatch({ type: CHANGE_CLIENT_ADDRESS, payload: response.result.data.address })
        response.result.data.points && dispatch({ type: CHANGE_CLIENT_POINTS, payload: response.result.data.points })

      })
    }

  }, [dispatch, isShopSelected, shopId])


  const isMobile = () => /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <HashRouter >
      {isMobile()
      ? <div className="global-container">
        <ActiveMenu/>
        <Header setOpenChangeShop={setOpenChangeShop}/>
        {openChangeShop && <DialogSelectShop setOpenChangeShop={setOpenChangeShop} />}
        {!isShopSelected && <DialogStartSelectShop setIsShopSelected={setIsShopSelected} />}
        {openAddNewShop && <WindowScreenAddNewShop setOpenAddNewShop={setOpenAddNewShop} />}
        {openEditNewShop && <WindowScreenEditNewShop 
          setOpenEditNewShop={setOpenEditNewShop} 
          shopIdInWindow={shopIdInWindow} 
          setShopIdInWindow={setShopIdInWindow} 
          isShopsLoad={isShopsLoad} 
          setIsShopsLoad={setIsShopsLoad} 
          adminShops={adminShops}
        />}
        <Routes>
          <Route path="*" element={<p>Такой страницы нет</p>} />
          <Route path="/" element={<Home />} />
          <Route path="/p/:id" element={<ProductPage />} />
          <Route path="/p" element={<Redirect />} />
          <Route path="/basket" element={<BasketPage />} />
          <Route path="/thank" element={<ThankPage />} />
          <Route path="/order" element={<BuildOrder />} />
          <Route path="/contacts" element={<Contacts />} />
          <Route path="/ctg/:id" element={<CategoryPage />} />
          <Route path="/adm7730s" element={<AdminPanel setOpenChangeShop={setOpenChangeShop}/>} />
          <Route path="/user_info" element={<UserInfo />} />
          <Route path="/adm7730s/ctgs" element={<CategoriesPage />} />
          <Route path="/adm7730s/contacts" element={<ContactsPage />} />
          <Route path="/adm7730s/orders" element={<OrdersPage />} />
          <Route path="/adm7730s/products" element={<ProductsPage />} />
          <Route path="/adm7730s/shops" element={<Shops 
          setOpenAddNewShop={setOpenAddNewShop} 
          setOpenEditNewShop={setOpenEditNewShop} 
          setShopIdInWindow={setShopIdInWindow} 
          isShopsLoad={isShopsLoad} 
          setIsShopsLoad={setIsShopsLoad} 
          adminShops={adminShops}
          setAdminShops={setAdminShops}
          />} />
          <Route path="/adm7730s/products/new" element={<AddProductPage />} />
        </Routes>
        {ws_info_points_target && <WindowScreenInfoPoints />}

      </div>
      : <BlockPCPage/>}
    </HashRouter>
    </LocalizationProvider>
  );
}

export default App;
